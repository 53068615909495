/** 列表参数 */
export const tableListDataByNature = [
    { title: "ID", align: "center", dataIndex: 'id', width: 80 },
    { title: "大币种/小币种/版别", align: 'center', scopedSlots: {customRender: 'itemCoinNameOrSmallCoinNameSlot'}, width: 120},
    { title: "性质", align: "center", scopedSlots: { customRender: "itemNatureTypeSlot" }, width: 120 },
    { title: "图片标题", align: 'center', dataIndex: 'title', width: 100},
    { title: "图片", align: "center", scopedSlots: { customRender: "itemImageSlot" }, width: 200 },
    { title: "图片说明", align: "center", scopedSlots: { customRender: "itemImageDescSlot" }, width: 220 },
    { title: "评级信息", align: 'center', scopedSlots: {customRender: 'itemRateDescSlot'}, width: 100},
    { title: "成交信息", align: "center", scopedSlots: { customRender: "itemTradeSlot" }, width: 120},
    { title: "版权", align: "center", dataIndex: 'copyright', width: 100},
    { title: "属性", align: "center", scopedSlots: { customRender: "itemAttrSlot" }, width: 150 },
    { title: "操作人", align: 'center', dataIndex: 'sysOper'},
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 100},
]