<template>
  <a-modal
      v-model="showPopup"
      title="增加图片性质"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="图片性质名称">
          <a-input
              allowClear
              v-model="params.natureName"
              placeholder='图片性质名称'
          ></a-input>
        </a-form-item>
        <a-form-item label="排序">
          <a-input
              allowClear
              v-model="params.sort"
              type="number"
              placeholder='排序'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {addBookImgTypeList} from "@/axios/apis"

export default {
  data() {
    return {
      showPopup: false,
      layout: {
        labelCol: {span: 6}, //标签占5份  共24份
        wrapperCol: {span: 18},
      },
      params: {
        id: undefined,
        natureName: undefined,
        sort: undefined,
      }
    }
  },
  methods: {
    /** 展示弹窗 */
    show() {
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.params.sort = undefined;
      this.params.natureName = undefined;
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.natureName) {
        return this.$message.warn('性质名称不能为空')
      }
      if (!this.params.sort) {
        return this.$message.warn('排序不能为空')
      }
      this.$loading.show()
      const res = await addBookImgTypeList(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('change')
      this.handleCancel()
    }
  }
}
</script>