var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        { staticClass: "page-header" },
        [
          _c(
            "div",
            [
              _c("a-input", {
                staticClass: "m-5",
                staticStyle: { width: "100px" },
                attrs: { allowClear: "", placeholder: "id", size: "small" },
                on: { pressEnter: _vm.search },
                model: {
                  value: _vm.params.id,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "id", $$v)
                  },
                  expression: "params.id"
                }
              }),
              _c(
                "a-select",
                {
                  staticClass: "m-5",
                  staticStyle: { "margin-left": "5px", width: "120px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    showSearch: "",
                    size: "small",
                    placeholder: "大币种"
                  },
                  on: { change: _vm.changeVersionlistSearch },
                  model: {
                    value: _vm.params.coinId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "coinId", $$v)
                    },
                    expression: "params.coinId"
                  }
                },
                _vm._l(_vm.bigCoinList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.coinId, attrs: { value: item.coinId } },
                    [_vm._v(_vm._s(item.coinName))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticClass: "m-5",
                  staticStyle: { "margin-left": "5px", width: "120px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    showSearch: "",
                    size: "small",
                    placeholder: "小币种"
                  },
                  on: { change: _vm.coinItemChange },
                  model: {
                    value: _vm.params.itemId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "itemId", $$v)
                    },
                    expression: "params.itemId"
                  }
                },
                _vm._l(_vm.smallCoinList, function(item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.coinKindItemId,
                      attrs: { value: item.coinKindItemId }
                    },
                    [_vm._v(_vm._s(item.coinKindItemName))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticClass: "m-5",
                  staticStyle: { "margin-left": "5px", width: "260px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    showSearch: "",
                    allowClear: "",
                    "filter-option": _vm.untils.filterOption,
                    size: "small",
                    dropdownMenuStyle: { "max-height": "500px" },
                    placeholder: "版别"
                  },
                  on: { select: _vm.search },
                  model: {
                    value: _vm.params.versionId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "versionId", $$v)
                    },
                    expression: "params.versionId"
                  }
                },
                _vm._l(_vm.versionList, function(item) {
                  return _c(
                    "a-select-option",
                    {
                      key: item.coinVersionId,
                      class: item.isDuiban ? "color-green" : "",
                      attrs: {
                        value: item.coinVersionId,
                        label: item.coinVersionName
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            "【" +
                              (item.level ? item.level : "无") +
                              "】 " +
                              item.coinVersionName +
                              "(" +
                              (item.size || 0) +
                              ")"
                          ) +
                          " "
                      )
                    ]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticClass: "m-5",
                  staticStyle: { "margin-left": "5px", width: "140px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    allowClear: "",
                    size: "small",
                    placeholder: "数据来源"
                  },
                  model: {
                    value: _vm.params.type,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "type", $$v)
                    },
                    expression: "params.type"
                  }
                },
                _vm._l(_vm.sourceList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticClass: "m-5",
                  staticStyle: { "margin-left": "5px", width: "140px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    allowClear: "",
                    size: "small",
                    placeholder: "底库是否存在"
                  },
                  model: {
                    value: _vm.isDiku,
                    callback: function($$v) {
                      _vm.isDiku = $$v
                    },
                    expression: "isDiku"
                  }
                },
                _vm._l(_vm.isDiKuTypeList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticClass: "m-5",
                  staticStyle: { "margin-left": "5px", width: "120px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    allowClear: "",
                    size: "small",
                    placeholder: "状态筛选"
                  },
                  model: {
                    value: _vm.params.waitStatus,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "waitStatus", $$v)
                    },
                    expression: "params.waitStatus"
                  }
                },
                _vm._l(_vm.waitStatusList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _c(
                "a-select",
                {
                  staticClass: "m-5",
                  staticStyle: { "margin-left": "5px", width: "120px" },
                  attrs: {
                    dropdownMatchSelectWidth: false,
                    allowClear: "",
                    size: "small",
                    placeholder: "是否支持对版"
                  },
                  model: {
                    value: _vm.params.isDuiban,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "isDuiban", $$v)
                    },
                    expression: "params.isDuiban"
                  }
                },
                _vm._l(_vm.isDuiBanList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              ),
              _c(
                "a-radio-group",
                {
                  staticClass: "m-5",
                  attrs: {
                    size: "small",
                    "default-value": 0,
                    "button-style": "solid"
                  },
                  model: {
                    value: _vm.imageType,
                    callback: function($$v) {
                      _vm.imageType = $$v
                    },
                    expression: "imageType"
                  }
                },
                [
                  _c("a-radio-button", { attrs: { value: 0 } }, [
                    _vm._v(" 正面图 ")
                  ]),
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v(" 背面图 ")
                  ]),
                  _c("a-radio-button", { attrs: { value: 2 } }, [
                    _vm._v(" 正反面 ")
                  ])
                ],
                1
              ),
              _c(
                "a-button",
                {
                  staticClass: "btn-item m-5",
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.search }
                },
                [_vm._v("搜索")]
              ),
              _c(
                "a-button",
                {
                  staticClass: "btn-item m-5",
                  attrs: { size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.uploadClick()
                    }
                  }
                },
                [_vm._v("上传")]
              ),
              _vm.showVersionImgStatus
                ? _c("ShowVersionImg", {
                    staticClass: "ml-20",
                    attrs: { info: _vm.showVersionImg }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.showVersionImgStatus
            ? _c("EditorVersionDescInfo", {
                attrs: { info: _vm.list[0] },
                on: {
                  changeVersionDesc: function($event) {
                    return _vm.handleChangeVersionDesc($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-body" },
        [
          _c("ConfirmListV2", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imageType !== 2,
                expression: "imageType !== 2"
              }
            ],
            attrs: { list: _vm.list, imageType: _vm.imageType },
            on: {
              deleteItem: _vm.getList,
              editSuccess: _vm.getList,
              cutPicSuccess: _vm.handleCutPicSuccess,
              sameVersion: _vm.handleShowSameVersion,
              changeVersionDesc: function($event) {
                return _vm.handleChangeVersionDesc($event)
              }
            }
          }),
          _c("ConfirmList", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.imageType === 2,
                expression: "imageType === 2"
              }
            ],
            attrs: { list: _vm.list },
            on: {
              deleteItem: _vm.getList,
              editSuccess: _vm.getList,
              cutPicSuccess: _vm.handleCutPicSuccess,
              sameVersion: _vm.handleShowSameVersion,
              changeVersionDesc: function($event) {
                return _vm.handleChangeVersionDesc($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("a-pagination", {
                attrs: {
                  pageSize: _vm.pagination.pageSize,
                  current: _vm.pagination.current,
                  total: _vm.pagination.total
                },
                on: {
                  "update:pageSize": function($event) {
                    return _vm.$set(_vm.pagination, "pageSize", $event)
                  },
                  "update:page-size": function($event) {
                    return _vm.$set(_vm.pagination, "pageSize", $event)
                  },
                  change: _vm.changePage,
                  editSuccess: _vm.editSuccess
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("ModalUpload", { ref: "upload", on: { success: _vm.getList } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }