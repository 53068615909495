var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 1000 },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [_c("div")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "div",
        { staticClass: "content-popup" },
        [
          _c(
            "a-descriptions",
            { attrs: { title: "评级相关信息", bordered: "" } },
            [
              _c("a-descriptions-item", { attrs: { label: "评级编号" } }, [
                _c("span", { staticClass: "color-green font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.ratingInfo.boxCode))
                ])
              ]),
              _c("a-descriptions-item", { attrs: { label: "评级公司" } }, [
                _c(
                  "span",
                  {
                    staticClass: "font-weight-bold",
                    class:
                      _vm.ratingCompanyClassType[_vm.ratingInfo.boxCompanyType]
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.computedRatingCompanyTypeListMapText(
                            _vm.ratingInfo.boxCompanyType
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]),
              _c("a-descriptions-item", { attrs: { label: "大币种" } }, [
                _c("span", { staticClass: "color-blue font-weight-bold" }, [
                  _vm._v(
                    _vm._s(
                      _vm.ratingInfo.itemTitle +
                        (_vm.ratingInfo.itemVersion || "")
                    )
                  )
                ])
              ]),
              _c("a-descriptions-item", { attrs: { label: "分数" } }, [
                _c("span", { staticClass: "color-black font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.ratingInfo.score))
                ])
              ]),
              _c("a-descriptions-item", { attrs: { label: "朝代" } }, [
                _c("span", { staticClass: "color-black font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.ratingInfo.itemYear))
                ])
              ]),
              _c("a-descriptions-item", { attrs: { label: "赔付等级" } }, [
                _c("span", { staticClass: "color-red font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.ratingInfo.payLevel))
                ])
              ]),
              _c("a-descriptions-item", { attrs: { label: "材质" } }, [
                _c("span", { staticClass: "color-black font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.ratingInfo.itemCaizhi))
                ])
              ]),
              _c("a-descriptions-item", { attrs: { label: "尺寸" } }, [
                _c("span", { staticClass: "color-black font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.ratingInfo.itemSize))
                ])
              ]),
              _c("a-descriptions-item", { attrs: { label: "质量" } }, [
                _c("span", { staticClass: "color-black font-weight-bold" }, [
                  _vm._v(_vm._s(_vm.ratingInfo.itemWeight))
                ])
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "*备注", span: 5 } },
                [
                  _c("span", { staticClass: "color-gray font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.ratingInfo.remark || "暂无"))
                  ])
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "官方原图", span: 5 } },
                [
                  _vm.ratingInfo.imageList
                    ? _c("div", { staticClass: "item-image-list" }, [
                        _c("img", {
                          staticClass: "item-image",
                          attrs: { src: _vm.ratingInfo.imageList[0], alt: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowBigImage(_vm.ratingInfo, 0)
                            }
                          }
                        }),
                        _c("img", {
                          staticClass: "item-image",
                          attrs: { src: _vm.ratingInfo.imageList[1], alt: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleShowBigImage(_vm.ratingInfo, 1)
                            }
                          }
                        })
                      ])
                    : _vm._e()
                ]
              ),
              _c("a-descriptions-item", { attrs: { label: "其他信息" } })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }