const mapNumberToString = {
    0: '零',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
    7: '七',
    8: '八',
    9: '九',
}

export const numberToString = (num) => {
    if (!num) return ''
    const str = num + ''
    if (str.length === 0) return ''
    const strArray = str.split('')
    let level = ''
    strArray.forEach(item => {
        level += mapNumberToString[Number(item)]
    })
    return level + '号'
}