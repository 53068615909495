<template>
  <a-modal
      v-model="showPopup"
      title="抠图验证"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <img class="w-200" :src="imgUrl" alt="" @click="handlePreview">
    </div>
  </a-modal>
</template>
<script>

export default {
  data() {
    return {
      showPopup: false,
      imgUrl: ""
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.imgUrl = ""
      this.imgUrl = data
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.imgUrl = ""
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      this.handleCancel()
    },
    handlePreview() {
      this.$previewImg({
        current: 0,
        list: [{img_url: this.imgUrl}],
        baseImgField: "img_url",
        showMute: false,
      });
    }
  }
}
</script>