<template>
  <a-modal
      v-model="showPopup"
      :width='1000'
  >
    <template #footer><div></div></template>
    <div class="content-popup">
      <a-descriptions title="评级相关信息" bordered>
        <a-descriptions-item label="评级编号">
          <span class="color-green font-weight-bold">{{ ratingInfo.boxCode }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="评级公司">
          <span class="font-weight-bold" :class="ratingCompanyClassType[ratingInfo.boxCompanyType]">
            {{ computedRatingCompanyTypeListMapText(ratingInfo.boxCompanyType) }}
          </span>
        </a-descriptions-item>
        <a-descriptions-item label="大币种">
          <span class="color-blue font-weight-bold">{{ ratingInfo.itemTitle + (ratingInfo.itemVersion || '') }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="分数">
            <span class="color-black font-weight-bold">{{ ratingInfo.score }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="朝代">
            <span class="color-black font-weight-bold">{{ ratingInfo.itemYear }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="赔付等级">
            <span class="color-red font-weight-bold">{{ ratingInfo.payLevel }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="材质">
            <span class="color-black font-weight-bold">{{ ratingInfo.itemCaizhi }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="尺寸">
            <span class="color-black font-weight-bold">{{ ratingInfo.itemSize }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="质量">
            <span class="color-black font-weight-bold">{{ ratingInfo.itemWeight }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="*备注" :span="5">
          <span class="color-gray font-weight-bold">{{ ratingInfo.remark || '暂无' }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="官方原图" :span="5">
          <div class="item-image-list" v-if="ratingInfo.imageList">
            <img class="item-image" :src="ratingInfo.imageList[0]" alt="" @click="handleShowBigImage(ratingInfo, 0)">
            <img class="item-image" :src="ratingInfo.imageList[1]" alt="" @click="handleShowBigImage(ratingInfo, 1)">
          </div>
        </a-descriptions-item>
        <a-descriptions-item label="其他信息">

        </a-descriptions-item>
      </a-descriptions>
    </div>
  </a-modal>
</template>
<script>
import {RATING_COMPANY_CLASS_TYPE, ratingCompanyTypeListMapText} from "@/views/cmsPage/coinBookManage/_data"

export default {
  data() {
    return {
      showPopup: false,
      ratingCompanyClassType: RATING_COMPANY_CLASS_TYPE,
      ratingInfo: ''
    }
  },
  computed: {
    computedRatingCompanyTypeListMapText: () => {
      return (value) => ratingCompanyTypeListMapText(value)
    }
  },
  methods: {
    show(data) {
      data.imageList = data.images.split(',')
      this.ratingInfo = data
      this.showPopup = true
    },
    /** 放大图片 */
    handleShowBigImage(row, index) {
      this.$previewImg({
        current: index,
        list: [{img_url: row.imageList[0]}, {img_url: row.imageList[1]}],
        baseImgField: "img_url",
        showMute: false,
      });
    },
  }
}
</script>
<style scoped lang="scss">
.item-image-list {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item-image {
    width: 100px;
  }
}
</style>