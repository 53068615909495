<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          size="small"
          v-model="params.searchTitle"
          class="w-200"
          placeholder='标题搜索'
      ></a-input>
      <!-- 选择大币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          size="small"
          @search='searchData'
          style="margin: 5px; width: 180px"
          v-model="params.coinId"
          placeholder="请选择大币种"
          @change="handleChangeCoin($event)"
      >
        <a-select-option
            v-for="items of bigCoinList"
            :key="items.id"
            :value="items.id"
        >{{ items.coinKindName }}</a-select-option>
      </a-select>
      <!-- 选择小币种 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          @change="coinItemChange"
          :filter-option="untils.filterOption"
          allowClear
          showSearch
          size="small"
          style="margin: 5px; width: 180px"
          v-model="params.coinItemId"
          placeholder="请选择小币种"
      >
        <a-select-option
            v-for="item of smallCoinList"
            :key="item.coinKindItemId"
            :value="item.coinKindItemId"
        >{{ item.coinKindItemName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          allowClear
          showSearch
          :filter-option="untils.filterOption"
          size="small"
          style="margin: 5px; width: 260px"
          v-model="params.coinItemVersionId"
          :dropdownMenuStyle="{'max-height': '500px'}"
          placeholder="请选择版别"
      >
        <a-select-option
            v-for="item of versionList"
            :key="item.coinVersionId"
            :value="item.coinVersionId"
        >{{ `【${item.level ?  item.level : "无"}】 ${item.coinVersionName}` }}</a-select-option>
      </a-select>
      <!-- 数据类型 -->
      <a-select
          :dropdownMatchSelectWidth="false"
          :filter-option="untils.filterOption"
          :dropdownMenuStyle="{'max-height': '350px'}"
          allowClear
          showSearch
          size="small"
          @search='searchData'
          style="margin: 5px; width: 180px"
          v-model="params.natureType"
          placeholder="请选择性质类型"
          @change="handleChangeCoin($event)"
      >
        <a-select-option
            v-for="(item, index) in natureTypeMapText"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          size="small"
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          size="small"
          @click="handleAdd"
      >录入</a-button>
    </div>
    <div class="table-content">
      <NatureTable
          :list="data"
          :pagination="pagination"
          :img-type-list="natureTypeMapText"
          @changePage="handleChangePage"
          @successStore="getList"
          @editorSuccess="getList"
      />
    </div>
    <!--  新增数据  -->
    <EditorPopup ref="editorPopupEl" @success="getList"/>
  </div>
</template>
<script>
import NatureTable from "@/views/cmsPage/coinBookManage/natureManage/_components/NatureTable/index.vue"
import untils from "@/untils";
import EditorPopup from "@/views/cmsPage/coinBookManage/natureManage/_components/EditorPopup/index.vue"
import { getBookImgType } from "@/axios/apis"

export default {
  components: {
    NatureTable, EditorPopup
  },
  computed: {
  },
  data() {
    return {
      untils,
      natureTypeMapText: [],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 20,
        natureType: undefined,
        coinId: undefined,
        coinItemId: undefined,
        coinItemVersionId: undefined,
        searchTitle: undefined
      },
      bigCoinList: [],
      smallCoinList: [],
      versionList: [],
    };
  },
  async mounted() {
    await this.getImgTypeList();
    await this.getList()
  },
  methods: {
    /** 获取图片性质 */
    async getImgTypeList() {
      const res = await getBookImgType();
      this.$loading.hide()
      if (res.status !== "200") return;
      this.natureTypeMapText = res.data.records.map(el => {
        return {value: el.id, name: el.natureName}
      })
    },
    /** 录入数据 */
    handleAdd() {
      let data
      if (this.params.coinItemVersionId) {
        data = {
          coinName: this.bigCoinText || undefined,
          coinId: this.params.coinId || undefined,
          coinItemId: this.params.coinItemId || undefined,
          coinItemVersionId: this.params.coinItemVersionId || undefined
        }
      } else {
        data = ''
      }
      this.$refs.editorPopupEl.show(data)
    },
    editSuccess() {
      this.getList()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getList('changePage')
    },
    /** 搜索 */
    search() {
      this.params.pageNum = 1
      this.pagination.current = 1
      this.listStatusByIdList = []
      this.getList()
    },
    /**  获取列表数据 */
    async getList(type) {
      this.loading = true
      this.$loading.show()
      const res = await this.axios("/dq_admin/coinVersionNature/list", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      this.loading = false
      if (res.status !== '200') return
      this.data = res.data.records
      this.pagination.total = res.data.total
    },
    /** 获取大币种列表 */
    searchData(inner){
      if (!inner) return
      this.axios('/dq_admin/kind/list',{params:{
          coinName: inner
        }}).then(res=>{
        if(res.status === '200'){
          const {records} = res.data
          this.bigCoinList = records
        }
      })
    },
    /** 选择大币种 */
    handleChangeCoin(e) {
      this.bigCoinText = this.bigCoinList.find(el => el.id === e).coinKindName
      delete this.params.coinItemId
      delete this.params.coinItemVersionId
      if (!e) {
        this.bigCoinList = []
        this.smallCoinList = []
        this.versionList = []
        return
      }
      this.getSmallCoinList();
    },
    /** 查询小币种 */
    async getSmallCoinList() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinItems", {
        params: {
          coinId: this.params.coinId
        },
      });
      if (res.status !== '200') return
      this.smallCoinList = res.data
    },
    /** 选择小币种 */
    coinItemChange(val) {
      delete this.params.coinItemVersionId
      if (!val) {
        this.versionList = []
        return
      }
      this.getVersionType()
    },
    /** 获取版别 */
    async getVersionType() {
      const res = await this.axios("/dq_admin/duibanConfirm/listCoinVersions", {
        params: {
          coinId: this.params.coinId,
          coinItemId: this.params.coinItemId,
          forbidTuku: 0
        },
      });
      if (res.status != 200) return
      this.versionList = res.data
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
