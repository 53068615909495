var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "抠图验证", "ok-text": "确认", "cancel-text": "取消" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirm },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c("div", { staticClass: "popup-content" }, [
        _c("img", {
          staticClass: "w-200",
          attrs: { src: _vm.imgUrl, alt: "" },
          on: { click: _vm.handlePreview }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }